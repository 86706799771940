


















































































import { Poll } from "@/services/api/secure-votes.service.types";
import { Vue, Component, Prop } from "vue-property-decorator";
import { Broadcast } from "../Broadcast/broadcast/types";
import ButtonMenuItemList from "../ButtonMenuItemList.vue";

@Component({
  components: {
    ButtonMenuItemList,
  },
})
export default class SecurePollResumptionDialog extends Vue {
  @Prop({ required: true }) dialog!: boolean;
  @Prop({ default: 800 }) maxWidth!: number;

  @Prop({ required: true }) broadcasts!: Broadcast[];
  @Prop({ required: true }) questionId!: string;
  @Prop({ required: true }) polls!: Poll[];

  threshold: string | null = null;
  thresholdItems: { label: string; value: string }[] = [
    {
      label: "50%",
      value: "50",
    },
    {
      label: "66.67%",
      value: "66",
    },
    {
      label: "75%",
      value: "75",
    },
  ];

  get filteredPolls(): Poll[] {
    return this.polls.filter((poll) => poll.questionId !== this.questionId);
  }

  pollBroadcasts(poll: Poll) {
    return this.broadcasts
      .filter((broadcast) => poll.broadcastIds.includes(broadcast.id))
      .map((b) => b.broadcastName);
  }

  close(): void {
    this.$emit("close");
  }

  select(prevoteQuestionId: string): void {
    this.$emit("select", this.questionId, this.threshold, prevoteQuestionId);
  }
}
