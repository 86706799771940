


















import PageTemplate from "@/components/PageTemplate.vue";
import SecurePollEditor from "@/components/SecureVotingPolls/SecurePollEditor.vue";
import SecurePollList from "@/components/SecureVotingPolls/SecurePollList.vue";
import SecurePollStats from "@/components/SecureVotingPolls/SecurePollStats.vue";
import { RouteName } from "@/router/types";
import { Vue, Component } from "vue-property-decorator";

@Component({
  components: {
    PageTemplate,
    SecurePollEditor,
    SecurePollList,
    SecurePollStats,
  },
})
export default class SecurePolls extends Vue {
  RouteName = RouteName;
}
