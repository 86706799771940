



























































































































































































































































































































































































































import {
  Broadcast,
  BroadcastGetters,
} from "@/components/Broadcast/broadcast/types";
import { Vue, Component, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import {
  PollActions,
  PollGetters,
  PresenceLog,
  PresenceSendObject,
} from "@/components/SecureVotingPolls/types";
import {
  Poll,
  PollAnswer,
  PollGroupSelection,
  PollStatusSelection,
  SVPollType,
  UpdatePollDto,
  UpdateStatus,
} from "@/services/api/secure-votes.service.types";
import {
  getPollGroupSelectionLabel,
  getPollStatusLabel,
  getPollStatusSelectionLabel,
  getPollTypeSelectionLabel,
  getPollVisibilityLabel,
  getStatusLabel,
} from "@/helpers";
import copy from "fast-copy";
import ButtonMenuItemList from "../ButtonMenuItemList.vue";
import SecurePollResumptionDialog from "./SecurePollResumptionDialog.vue";
import moment from "moment";
import { Channel } from "@/store/generalSettings/types";

@Component({
  components: {
    ButtonMenuItemList,
    SecurePollResumptionDialog,
  },
})
export default class SecureVoting extends Vue {
  @Getter(PollGetters.Polls)
  readonly polls!: Poll[];
  @Getter(PollGetters.EditPoll)
  readonly editPoll!: Poll | null;

  @Action(PollActions.SetEditPoll)
  setEditPoll!: (poll: Poll) => void;
  @Action(PollActions.DeletePoll)
  deletePoll!: (pollId: string) => void;
  @Action(PollActions.UpdatePoll)
  updatePoll!: (poll: UpdatePollDto) => void;
  @Action(PollActions.FetchPolls)
  fetchPolls!: () => Promise<void>;
  @Action(PollActions.UpdateStatus)
  updateEditedStatus!: (status: UpdateStatus) => void;
  @Action(PollActions.SendOverlayEvent)
  sendOverlayEvent!: (questionId: string) => Promise<void>;
  @Action(PollActions.UpdatePollVotes)
  updatePollVotes!: (questionId: string) => void;
  @Action(PollActions.FetchPresenceStats)
  fetchPresenceStats!: (questionId: string) => Promise<PresenceLog[]>;
  @Action(PollActions.SendPresence)
  SendPresence!: (presenceSendObject: PresenceSendObject) => Promise<void>;

  @Getter("generalSettings/allChannels")
  readonly allChannels!: Channel[];
  @Getter(BroadcastGetters.Broadcasts)
  readonly broadcasts!: Broadcast[];

  getPollTypeLabel = getPollTypeSelectionLabel;
  getPollStatusLabel = getPollStatusLabel;
  getPollVisibilityLabel = getPollVisibilityLabel;
  getStatusLabel = getStatusLabel;

  SVPollType = SVPollType;

  PollStatusSelection = PollStatusSelection;
  PollGroupSelection = PollGroupSelection;

  editStatus: Poll | null = null;
  editStatusRef: Poll | null = null;

  saving = false;

  dialog = false;
  emailsToSend = "";

  selectedQuestionId = "";

  expanded = [];
  search = "";
  selectedGroup: PollGroupSelection | null = null;
  selectedStatus: PollStatusSelection | null = null;

  resumptionDialog = false;
  resumptionDialogQuestionId: string | null = null;

  get pollHeaders() {
    return [
      {
        text: this.$t("polls.status"),
        value: "status",
        width: "147px",
      },
      { text: this.$t("polls.type"), value: "type" },
      { text: this.$t("Title"), value: "title", width: "20%" },
      { text: this.$t("polls.question"), value: "question", width: "20%" },
      { text: this.$t("polls.group"), value: "targetGroup", width: "100px" },
      {
        text: this.$t("polls.memberStatus"),
        value: "targetMemberStatus",
        width: "100px",
      },
      {
        text: this.$t("polls.broadcasts"),
        value: "broadcasts",
        width: "120px",
      },
      {
        text: this.$t("polls.actions"),
        value: "actions",
        sortable: false,
        width: "230px",
      },
      { text: "", value: "data-table-expand" },
    ];
  }

  cancelDialog() {
    this.dialog = false;
    this.emailsToSend = "";
    this.selectedQuestionId = "";
  }

  openDialog(questionId: string) {
    this.dialog = true;
    this.selectedQuestionId = questionId;
  }

  async sendStatistics() {
    const emailRecipients: string[] = this.emailsToSend
      .split(",")
      .map((email) => email.trim().toLowerCase());
    const ccAddresses: string[] = [
      "toni.wagner@vaudience.net",
      "marc.schmitt@vaudience.net",
    ];
    await this.SendPresence({
      questionId: this.selectedQuestionId,
      emailRecipients,
      ccAddresses,
    });
    this.cancelDialog();
  }

  clearSelection() {
    this.selectedGroup = null;
    this.selectedStatus = null;
  }

  get filteredPolls() {
    let selectedTargetGroup: PollGroupSelection | null = this.selectedGroup;
    if (this.selectedGroup === PollGroupSelection.ALL) {
      selectedTargetGroup = null;
    }
    if (this.selectedGroup == null && this.selectedStatus == null) {
      return this.polls;
    } else if (this.selectedGroup != null && this.selectedStatus == null) {
      return this.polls.filter(
        (poll) => poll.targetGroup === selectedTargetGroup
      );
    } else if (this.selectedGroup == null && this.selectedStatus != null) {
      return this.polls.filter((poll) => poll.status === this.selectedStatus);
    }
    return this.polls.filter(
      (poll) =>
        poll.targetGroup === selectedTargetGroup &&
        poll.status === this.selectedStatus
    );
  }

  get closedPolls(): Poll[] {
    return this.polls.filter(
      (poll) =>
        poll.status === PollStatusSelection.CLOSED &&
        poll.type === SVPollType.POLL
    );
  }

  @Watch("selectedGroup")
  onSelectedGroupChange(group: PollGroupSelection | null) {
    this.selectedGroup = group;
  }

  @Watch("selectedStatus")
  onSelectedStatusChange(status: PollStatusSelection | null) {
    this.selectedStatus = status;
  }

  created(): void {
    this.fetchPolls();
  }

  pollStatusItems(
    selectedStatus: PollStatusSelection,
    poll: Poll
  ): {
    label: string;
    value: PollStatusSelection;
  }[] {
    const statusOptions = Object.values(PollStatusSelection);

    const items = statusOptions
      .filter((status) => {
        if (selectedStatus === PollStatusSelection.PREPARING) {
          return status === PollStatusSelection.PRE_VOTE;
        } else if (selectedStatus === PollStatusSelection.PRE_VOTE) {
          return status === PollStatusSelection.HIDDEN;
        } else if (selectedStatus === PollStatusSelection.HIDDEN) {
          return status === PollStatusSelection.LIVE;
        } else if (selectedStatus === PollStatusSelection.LIVE) {
          return (
            status === PollStatusSelection.HIDDEN ||
            status === PollStatusSelection.CLOSED
          );
        }
      })
      .map((option) => {
        return {
          label: getPollStatusSelectionLabel(option),
          value: option,
          disabled:
            selectedStatus === PollStatusSelection.HIDDEN
              ? this.checkIsItemDisabled(poll.broadcastIds)
              : false,
        };
      });

    return items;
  }

  get pollStatusFilters(): {
    label: string;
    value: PollStatusSelection;
  }[] {
    const statusOptions = Object.values(PollStatusSelection);

    let items = statusOptions.map((option) => {
      return {
        label: getPollStatusSelectionLabel(option),
        value: option,
      };
    });

    return items;
  }

  get pollGroupFilters(): {
    label: string;
    value: PollGroupSelection;
  }[] {
    const statusOptions = Object.values(PollGroupSelection);

    let items = statusOptions.map((option) => {
      return {
        label: getPollGroupSelectionLabel(option),
        value: option,
      };
    });

    return items;
  }

  async updateVotes(questionId: string) {
    await this.updatePollVotes(questionId);
  }

  sumNumberOfUsersWithVotingRights(logs?: PresenceLog[] | null): string {
    if (!logs) {
      return "-/-";
    }
    const inPerson = logs.reduce(
      (accumulator, current) =>
        accumulator +
        (current.currentNumberOfUsersWithVotingRightsInPerson ?? 0),
      0
    );
    const digital = logs.reduce(
      (accumulator, current) =>
        accumulator +
        (current.currentNumberOfUsersWithVotingRightsDigital ?? 0),
      0
    );
    return `${inPerson}/${digital}`;
  }

  sumNumberOfVotes(logs?: PresenceLog[] | null): string {
    if (!logs) {
      return "-/-";
    }
    const inPerson = logs.reduce(
      (accumulator, current) =>
        accumulator + (current.currentNumberOfVotesInPerson ?? 0),
      0
    );
    const digital = logs.reduce(
      (accumulator, current) =>
        accumulator + (current.currentNumberOfVotesDigital ?? 0),
      0
    );
    return `${inPerson}/${digital}`;
  }

  timestampDate(date: string): string {
    return date ? moment(date).format("DD.MM.YYYY HH:mm:ss") : "-";
  }

  get liveBroadcastIdList() {
    return this.polls
      .filter((poll) => poll.status === PollStatusSelection.LIVE)
      .map((livePoll) => livePoll.broadcastIds);
  }

  checkIsItemDisabled(broadcasts: string[] | null) {
    if (
      this.liveBroadcastIdList.some((list) => list === null) ||
      (this.liveBroadcastIdList.length > 0 && !broadcasts)
    ) {
      return true;
    }

    const broadcastIdsInUse = this.liveBroadcastIdList
      .map((liveBroadcastIds) =>
        liveBroadcastIds.filter((id) => broadcasts?.includes(id))
      )
      .filter((liveBroadcastIds) => liveBroadcastIds?.length > 0);

    return (
      this.liveBroadcastIdList.some(
        (liveBroadcastIds) => liveBroadcastIds?.length === 0
      ) ||
      (broadcasts?.length === 0 && this.liveBroadcastIdList?.length >= 1) ||
      broadcastIdsInUse?.length >= 1
    );
  }

  updateStatus() {
    if (!this.editStatus || !this.editStatusRef) return;
    this.saving = true;

    this.updateEditedStatus({
      id: this.editStatus.questionId,
      status: this.editStatus.status,
    });

    this.editStatusRef.status = this.editStatus.status;
    this.saving = false;
    this.editStatus = null;
    this.editStatusRef = null;
  }

  get saveEditStatusDisabled(): boolean {
    if (this.saving) return true;

    if (this.editStatusRef && this.editStatus) {
      if (this.editStatusRef.status !== this.editStatus.status) {
        return false;
      }
    }

    return true;
  }

  editPollClick(item: Poll) {
    this.setEditPoll(item);
    this.editStatus = null;
  }

  setEditStatus(poll: Poll) {
    this.editStatusRef = poll;
    this.editStatus = copy(poll);
  }

  async sendOverlay(questionId: string) {
    await this.sendOverlayEvent(questionId);
  }

  closeResumptionDialog() {
    this.resumptionDialog = false;
    this.resumptionDialogQuestionId = null;
  }

  showResumptionDialog(questionId: string) {
    this.resumptionDialog = true;
    this.resumptionDialogQuestionId = questionId;
  }

  selectPoll(
    questionId: string,
    threshold?: string,
    prevoteQuestionId?: string
  ) {
    this.showPoll(questionId, threshold, prevoteQuestionId);
    this.closeResumptionDialog();
  }

  showPoll(questionId: string, threshold?: string, prevoteQuestionId?: string) {
    let url = `/interactive/secure-polls/${questionId}`;
    const query = [];
    if (threshold) {
      query.push(`threshold=${threshold}`);
    }
    if (prevoteQuestionId) {
      query.push(`prevoteQuestionId=${prevoteQuestionId}`);
    }
    if (query.length > 0) {
      url += "?" + query.join("&");
    }
    window.open(url, "_blank");
  }

  pollBroadcasts(poll: Poll) {
    return this.broadcasts
      .filter((broadcast) => poll.broadcastIds?.includes(broadcast.id))
      .map((b) => b.broadcastName);
  }

  get isEditingPoll(): boolean {
    return this.editPoll ? true : false;
  }

  calculateVotePercent(poll: Poll, { voteCount }: PollAnswer): string {
    const totalVotes = poll.answerVotes.reduce(function (
      voteCount,
      currentAnswer
    ) {
      return voteCount + (currentAnswer.voteCount ?? 0);
    },
    0);

    if (!voteCount) {
      return totalVotes > 0 ? "0%" : this.$t("polls.noVotes").toString();
    }

    return parseFloat((voteCount * (100 / totalVotes)).toFixed(2)) + "%";
  }
}
