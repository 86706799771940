var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticStyle:{"width":"100%"},attrs:{"footer-props":{
      'items-per-page-text': _vm.$t('dataTable.rowsPerPage'),
    },"headers":_vm.pollHeaders,"items":_vm.filteredPolls,"search":_vm.search,"item-key":"questionId","expanded":_vm.expanded,"show-expand":"","sort-by":"title","must-sort":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"table-header-search"},[_c('p',{staticClass:"text-subtitle-1 font-weight-semi-bold mb-0"},[_vm._v(" "+_vm._s(_vm.$t("polls.header"))+" ("+_vm._s(_vm.polls.length)+") ")]),_c('v-spacer'),_c('v-btn',{staticClass:"ma-0 pa-2 background--text",attrs:{"single-line":"","hide-details":"","color":"brand"},on:{"click":function($event){return _vm.clearSelection()}}},[_vm._v("Clear")]),_c('v-select',{staticClass:"ma-0 pa-1",attrs:{"label":"Group Type","items":_vm.pollGroupFilters,"item-text":"label","single-line":"","hide-details":"","color":"primary"},model:{value:(_vm.selectedGroup),callback:function ($$v) {_vm.selectedGroup=$$v},expression:"selectedGroup"}}),_c('v-select',{staticClass:"ma-0 pa-1",attrs:{"label":"Status","items":_vm.pollStatusFilters,"item-text":"label","single-line":"","hide-details":"","color":"primary"},model:{value:(_vm.selectedStatus),callback:function ($$v) {_vm.selectedStatus=$$v},expression:"selectedStatus"}}),_c('v-text-field',{staticClass:"ma-0 pa-0",attrs:{"prepend-icon":"mdi-magnify","placeholder":_vm.$t('forms.search'),"single-line":"","hide-details":"","color":"primary"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
    var item = ref.item;
return [_c('td',{attrs:{"colspan":_vm.pollHeaders.length}},[[(item.status === _vm.PollStatusSelection.CLOSED)?_c('v-row',{attrs:{"no-gutters":""}},[_c('div',{staticClass:"font-weight-bold mt-4"},[_vm._v(" "+_vm._s(_vm.$t("polls.statistic"))+" ")])]):_vm._e(),_c('v-row',{staticClass:"mt-4",attrs:{"no-gutters":""}},[(item.status === _vm.PollStatusSelection.CLOSED)?_c('v-col',[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{attrs:{"id":"answer"}}),_c('th',{attrs:{"id":"value"}}),_c('th',{staticClass:"text-center",attrs:{"id":"preVote"}},[_vm._v(" "+_vm._s(_vm.$t("polls.preVotes"))+" ")]),_c('th',{staticClass:"text-center",attrs:{"id":"liveVote"}},[_vm._v(" "+_vm._s(_vm.$t("polls.liveVotes"))+" ")]),_c('th',{staticClass:"text-center",attrs:{"id":"total"}},[_vm._v(" "+_vm._s(_vm.$t("polls.total"))+" ")])])]),_c('tbody',_vm._l((item.answerVotes),function(answer,index){return _c('tr',{key:index},[_c('td',{attrs:{"headers":"answer"}},[_vm._v(" "+_vm._s(_vm.$t("polls.answer"))+" ")]),_c('td',{attrs:{"headers":"value"}},[_vm._v(" "+_vm._s(answer.value)+" ")]),_c('td',{staticClass:"text-center",attrs:{"headers":"preVote"}},[_vm._v(" "+_vm._s(answer.preVoteCount)+" ")]),_c('td',{staticClass:"text-center",attrs:{"headers":"liveVote"}},[_vm._v(" "+_vm._s(answer.liveVoteCount)+" ")]),_c('td',{staticClass:"text-center",attrs:{"headers":"total"}},[_vm._v(" "+_vm._s(answer.voteCount)+" ("+_vm._s(_vm.calculateVotePercent(item, answer))+") ")])])}),0)]},proxy:true}],null,true)})],1):_c('v-col',[_c('td',[_c('span',[_vm._v(_vm._s(_vm.$t("polls.totalVotes")))])]),_c('td',[_c('span',{staticClass:"ml-5"},[_vm._v(_vm._s(item.totalVotes))])])]),(
                item.status === _vm.PollStatusSelection.CLOSED ||
                item.status === _vm.PollStatusSelection.LIVE
              )?_c('v-col',[_c('div',{staticClass:"ml-16 font-weight-bold"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t("polls.statsRetrived") + " " + _vm.timestampDate(item.logTimestamp))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t("polls.eligibleVotersPresent") + " " + _vm.sumNumberOfUsersWithVotingRights(item.presenceLogs))+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$t("polls.votersPresent") + " " + _vm.sumNumberOfVotes(item.presenceLogs))+" ")])])]):_vm._e()],1),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('div',{staticClass:"my-4 text-right"},[_c('v-btn',{staticClass:"brand secondary--text",attrs:{"small":"","depressed":"","elevation":"1"},on:{"click":function($event){return _vm.updateVotes(item.questionId)}}},[_vm._v(" "+_vm._s(_vm.$t("polls.refreshResult"))+" ")])],1)]),(
                item.status === _vm.PollStatusSelection.CLOSED ||
                item.status === _vm.PollStatusSelection.LIVE
              )?_c('v-col',[_c('div',{staticClass:"my-4 text-right"},[_c('v-btn',{staticClass:"brand secondary--text mr-5",attrs:{"small":"","depressed":"","elevation":"1"},on:{"click":function($event){return _vm.openDialog(item.questionId)}}},[_vm._v(" "+_vm._s(_vm.$t("polls.sendStats"))+" ")]),_c('v-btn',{staticClass:"brand secondary--text",attrs:{"small":"","depressed":"","elevation":"1"},on:{"click":function($event){return _vm.fetchPresenceStats(item.questionId)}}},[_vm._v(" "+_vm._s(_vm.$t("polls.requestStats"))+" ")])],1)]):_vm._e()],1)]],2)]}},{key:"item.type",fn:function(ref){
              var item = ref.item;
return [_c('td',{staticClass:"tdAlign"},[_vm._v(" "+_vm._s(_vm.getPollTypeLabel(item.type))+" ")])]}},{key:"item.title",fn:function(ref){
              var item = ref.item;
return [_c('td',{staticClass:"tdAlign"},[_vm._v(" "+_vm._s(item.title)+" ")])]}},{key:"item.targetGroup",fn:function(ref){
              var item = ref.item;
return [_c('td',{staticClass:"tdAlign"},[_vm._v(" "+_vm._s(item.targetGroup ? item.targetGroup : _vm.$t("polls.all"))+" ")])]}},{key:"item.targetMemberStatus",fn:function(ref){
              var item = ref.item;
return [_c('td',{staticClass:"tdAlign"},[_vm._v(" "+_vm._s(item.targetMemberStatus ? item.targetMemberStatus : _vm.$t("polls.all"))+" ")])]}},{key:"item.status",fn:function(ref){
              var item = ref.item;
return [(!_vm.editStatus || _vm.editStatus.questionId !== item.questionId)?[_c('td',{staticClass:"tdAlign text-uppercase",attrs:{"height":"56px"}},[_vm._v(" "+_vm._s(_vm.getStatusLabel(item.status))+" ")])]:[_c('v-select',{staticStyle:{"max-width":"120px"},attrs:{"items":_vm.pollStatusItems(item.status, item),"item-text":"label","dense":"","item-disabled":"disabled"},model:{value:(_vm.editStatus.status),callback:function ($$v) {_vm.$set(_vm.editStatus, "status", $$v)},expression:"editStatus.status"}})]]}},{key:"item.broadcasts",fn:function(ref){
              var item = ref.item;
return [_c('td',{staticClass:"tdAlign"},[_c('ButtonMenuItemList',{attrs:{"buttonText":_vm.$t('polls.broadcasts'),"emptyText":_vm.$t('polls.all'),"items":_vm.pollBroadcasts(item)}})],1)]}},{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-nowrap"},[(!_vm.editStatus || _vm.editStatus.questionId !== item.questionId)?[(_vm.PollStatusSelection.CLOSED != item.status)?_c('v-btn',{staticClass:"mr-1 brand secondary--text",attrs:{"small":"","depressed":"","elevation":"1","disabled":_vm.saving || _vm.isEditingPoll},on:{"click":function($event){return _vm.setEditStatus(item)}}},[_vm._v(" "+_vm._s(_vm.$t("polls.changeStatus"))+" ")]):_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-1 brand--text",attrs:{"color":"background","elevation":"1","small":"","depressed":"","disabled":_vm.saving || _vm.isEditingPoll}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t("polls.showResults"))+" ")])]}}],null,true)},[_c('v-list',[(item.type === _vm.SVPollType.POLL)?[_c('v-list-item',{on:{"click":function($event){return _vm.showPoll(item.questionId, '50')}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("polls.inAdminPanelNum", { num: 50 })))])],1),_c('v-list-item',{on:{"click":function($event){return _vm.showPoll(item.questionId, '66')}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("polls.inAdminPanelNum", { num: 66.67 })))])],1),_c('v-list-item',{on:{"click":function($event){return _vm.showPoll(item.questionId, '75')}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("polls.inAdminPanelNum", { num: 75 })))])],1),_c('v-list-item',{on:{"click":function($event){return _vm.showResumptionDialog(item.questionId)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("polls.resumption")))])],1)]:_c('v-list-item',{on:{"click":function($event){return _vm.showPoll(item.questionId)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("polls.inAdminPanel")))])],1),_c('v-list-item',{on:{"click":function($event){return _vm.sendOverlay(item.questionId)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("polls.inBroadcast")))])],1)],2)],1)]:[_c('v-btn',{staticClass:"mr-1 brand secondary--text",attrs:{"small":"","depressed":"","disabled":_vm.saveEditStatusDisabled},on:{"click":_vm.updateStatus}},[_vm._v(" "+_vm._s(_vm.$t("polls.apllyChanges"))+" ")]),_c('v-btn',{attrs:{"color":"error","small":"","depressed":"","disabled":_vm.saving},on:{"click":function($event){_vm.editStatus = null}}},[_vm._v(" "+_vm._s(_vm.$t("polls.discardChanges"))+" ")])],_c('v-spacer',{staticClass:"mr-2"}),(
            _vm.PollStatusSelection.PREPARING === item.status ||
            _vm.PollStatusSelection.PRE_VOTE === item.status ||
            _vm.PollStatusSelection.HIDDEN === item.status
          )?_c('v-icon',{staticClass:"mr-3",attrs:{"small":"","disabled":_vm.isEditingPoll},on:{"click":function($event){return _vm.editPollClick(item)}}},[_vm._v(" mdi-pencil ")]):_vm._e(),(_vm.PollStatusSelection.PREPARING === item.status)?_c('v-icon',{staticClass:"mr-n5",attrs:{"small":"","disabled":_vm.isEditingPoll},on:{"click":function($event){return _vm.deletePoll(item.questionId)}}},[_vm._v(" mdi-delete ")]):_vm._e()],2)]}}],null,true)}),_c('SecurePollResumptionDialog',{attrs:{"dialog":_vm.resumptionDialog,"broadcasts":_vm.broadcasts,"questionId":_vm.resumptionDialogQuestionId,"polls":_vm.closedPolls},on:{"close":_vm.closeResumptionDialog,"select":_vm.selectPoll}}),_c('v-dialog',{attrs:{"max-width":"540"},on:{"click:outside":_vm.cancelDialog},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"py-6",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"h5 pt-0"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('polls.emailDialog.title'))}})]),_c('v-col',{staticClass:"px-6"},[_c('v-text-field',{attrs:{"persistent-placeholder":"","dense":"","hide-details":"auto","color":"brand"},model:{value:(_vm.emailsToSend),callback:function ($$v) {_vm.emailsToSend=$$v},expression:"emailsToSend"}})],1),_c('v-card-actions',{staticClass:"px-6 pb-0"},[_c('v-spacer'),_c('v-btn',{staticClass:"secondary--text px-6",attrs:{"color":"red","depressed":""},on:{"click":_vm.cancelDialog}},[_vm._v(" "+_vm._s(_vm.$t("polls.emailDialog.cancel"))+" ")]),_c('v-btn',{staticClass:"secondary--text px-6",attrs:{"color":"brand","depressed":"","disabled":!_vm.emailsToSend},on:{"click":_vm.sendStatistics}},[_vm._v(" "+_vm._s(_vm.$t("polls.emailDialog.send"))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }