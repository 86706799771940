


























































import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { Broadcast, BroadcastGetters } from "../Broadcast/broadcast/types";
import PollService from "@/services/api/secure-votes.service";

@Component({})
export default class SecurePollStats extends Vue {
  @Getter(BroadcastGetters.Broadcasts)
  readonly broadcasts!: Broadcast[];

  selectedBroadcast: Broadcast | null = null;

  usersNowPresent = 0;
  usersNowDigital = 0;

  usersCumPresent = 0;
  usersCumDigital = 0;

  votesNowPresent = 0;
  votesNowDigital = 0;

  votesCumPresent = 0;
  votesCumDigital = 0;

  lastFetch = "";

  fetchVoteStats() {
    if (this.selectedBroadcast)
      PollService.getVotePresence(this.selectedBroadcast?.id).then((res) => {
        this.usersNowPresent = res.currentNumberOfUsersWithVotingRightsInPerson;
        this.usersNowDigital = res.currentNumberOfUsersWithVotingRightsDigital;
        this.usersCumPresent =
          res.accumulatedNumberOfUsersWithVotingRightsInPerson;
        this.usersCumDigital =
          res.accumulatedNumberOfUsersWithVotingRightsDigital;
        this.votesNowPresent = res.currentNumberOfVotesInPerson;
        this.votesNowDigital = res.currentNumberOfVotesDigital;
        this.votesCumPresent = res.accumulatedNumberOfVotesInPerson;
        this.votesCumDigital = res.accumulatedNumberOfVotesDigital;
        this.lastFetch = new Date(Date.now()).toLocaleString(["de"]);
      });
  }

  get usersNow() {
    return `${this.usersNowPresent}/${this.usersNowDigital}`;
  }

  get usersCum() {
    return `${this.usersCumPresent}/${this.usersCumDigital}`;
  }

  get votesNow() {
    return `${this.votesNowPresent}/${this.votesNowDigital}`;
  }

  get votesCum() {
    return `${this.votesCumPresent}/${this.votesCumDigital}`;
  }
}
