





































































































































































































import {
  Broadcast,
  BroadcastGetters,
} from "@/components/Broadcast/broadcast/types";
import { Vue, Component, Ref, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { PollActions, PollGetters } from "@/components/SecureVotingPolls/types";
import {
  MessageRouterPayload,
  NewPollAnswerDto,
  Poll,
  PollAnswer,
  PollDto,
  PollGroupSelection,
  PollMemberStatusSelection,
  PollStatusSelection,
  SVPollType,
  UpdatePollDto,
} from "@/services/api/secure-votes.service.types";
import { FormRule, stringLength } from "@/helpers/form.validators";
import { VForm } from "@/types";
import copy from "fast-copy";
import {
  getMemberStatusSelectionLabel,
  getPollGroupSelectionLabel,
  getPollStatusSelectionLabel,
  getPollTypeSelectionLabel,
  showNotification,
} from "@/helpers";
import { CoreConfigGetters, TenantConfig } from "@/spect8-core-vue/src/types";

@Component({})
export default class PollEditor extends Vue {
  // TODO: channel selection is disabled
  // TODO: update typings, e.g. minVotes vs minimumVotes

  // Create, Update, Delete poll actions
  @Action(PollActions.CreatePoll)
  createPoll!: (data: PollDto) => Promise<Poll>;
  @Action(PollActions.UpdatePoll)
  updatePoll!: (data: UpdatePollDto) => Promise<Poll>;

  @Action(PollActions.ClearEditPoll)
  clearEditPoll!: () => Promise<void>;

  @Getter(PollGetters.EditPoll)
  editPoll!: Poll | null;

  @Getter(BroadcastGetters.Broadcasts)
  readonly broadcasts!: Broadcast[];

  @Getter(CoreConfigGetters.TenantConfig)
  readonly tenantConfig!: TenantConfig;

  @Ref("pollEditForm") form!: VForm;

  saving = false;

  PollStatusSelection = PollStatusSelection;
  selectedStatus = PollStatusSelection.PREPARING;
  selectedGroup = PollGroupSelection.T;
  selectedMemberStatus = PollMemberStatusSelection.O;

  SVPollType = SVPollType;

  formValid = false;
  questionRules: FormRule[] = [stringLength(1, 250)];

  selectedBroadcasts: Broadcast[] = [];

  pollData: Poll = {} as Poll;

  editOnlyBroadcasts = false;

  created(): void {
    this.pollData = this.getInitialPollData();
  }

  @Watch("editPoll")
  onEditPollChange() {
    this.editPoll?.status === PollStatusSelection.PRE_VOTE ||
    this.editPoll?.status === PollStatusSelection.HIDDEN
      ? (this.editOnlyBroadcasts = true)
      : (this.editOnlyBroadcasts = false);

    this.pollData = this.getInitialPollData();
    this.selectedGroup = this.editPoll?.targetGroup || PollGroupSelection.T;
    this.selectedMemberStatus =
      this.editPoll?.targetMemberStatus || PollMemberStatusSelection.O;
    this.selectedStatus =
      this.editPoll?.status || PollStatusSelection.PREPARING;

    if (!this.editPoll) {
      this.form.resetValidation(); // Ensure no validation errors have been triggered
    }
  }

  get isEditingPoll(): boolean {
    return this.editPoll ? true : false;
  }

  onPollTypeSelection(): void {
    switch (this.pollData.type) {
      case SVPollType.ELECTION:
        this.pollData.answerVotes = [this.newAnswer(), this.newAnswer()];
        this.form.resetValidation();
        break;

      case SVPollType.POLL:
        this.pollData.answerVotes = [
          this.newAnswer("Ja"),
          this.newAnswer("Nein"),
          this.newAnswer("Enthaltung"),
        ];
        this.form.resetValidation();
        break;

      default:
        break;
    }
  }

  async discardPollChanges() {
    if (this.editPoll) {
      await this.clearEditPoll();
    }

    this.pollData = this.getInitialPollData();
    this.form.resetValidation();
    this.selectedBroadcasts = [];
    this.selectedStatus = PollStatusSelection.PREPARING;
    this.selectedGroup = PollGroupSelection.T;
    this.selectedMemberStatus = PollMemberStatusSelection.O;
  }

  getInitialPollData(): Poll {
    if (this.editPoll) {
      //   this.selectedChannels = [];
      this.selectedBroadcasts = this.broadcasts.filter(
        (broadcast) =>
          this.editPoll &&
          this.editPoll.broadcastIds &&
          this.editPoll.broadcastIds.includes(broadcast.id)
      );

      if (this.form) {
        this.form.resetValidation(); // Ensure no validation errors have been triggered
      }

      return {
        ...copy(this.editPoll),
        minVotes: this.editPoll.minimumVotes,
        maxVotes: this.editPoll.maximumVotes,
      };
    }

    return {
      questionId: "",
      question: "",
      answerVotes: [this.newAnswer(), this.newAnswer()],
      tenantId: this.tenantConfig.tenantId,
      status: PollStatusSelection.PREPARING,
      title: "",
      broadcastIds: this.messageRouterPayload.broadcastIds,
      targetMemberStatus: this.selectedMemberStatus,
      targetGroup: this.selectedGroup,
      type: SVPollType.ELECTION,
      uniqueAbstainedVotes: 0,
      uniqueValidVotes: 0,
      uniqueVotes: 0,
      minVotes: 0,
      maxVotes: 1,
    };
  }

  async savePoll(): Promise<void> {
    if (!this.form.validate()) return;

    const successMessage = this.pollData.questionId
      ? "polls.sucessPollUpdate"
      : "polls.sucessPollCreate";

    try {
      this.saving = true;

      if (!this.pollData.questionId) {
        await this.createPoll({
          ...this.pollData,
          tenantId: this.pollData.tenantId,
          answers: this.createNewPollAnswers(),
          broadcastIds: this.messageRouterPayload.broadcastIds,
          targetMemberStatus:
            this.pollData.targetMemberStatus != PollMemberStatusSelection.ALL
              ? this.pollData.targetMemberStatus
              : null,
          targetGroup:
            this.pollData.targetGroup != PollGroupSelection.ALL
              ? this.pollData.targetGroup
              : null,
          link: "",
        });

        showNotification(200, successMessage);
        this.discardPollChanges();
      } else {
        await this.updatePoll({
          ...this.pollData,
          broadcastIds: this.messageRouterPayload.broadcastIds,
          id: this.pollData.questionId,
          answers: this.pollData.answerVotes,
          targetMemberStatus:
            this.pollData.targetMemberStatus != PollMemberStatusSelection.ALL
              ? this.pollData.targetMemberStatus
              : null,
          targetGroup:
            this.pollData.targetGroup != PollGroupSelection.ALL
              ? this.pollData.targetGroup
              : null,
        });

        showNotification(200, successMessage);
        this.discardPollChanges();
      }
    } catch (error) {
      // TODO: Error handling
    } finally {
      this.saving = false;
    }
  }

  get messageRouterPayload(): MessageRouterPayload {
    const broadcastIds: Set<string> = new Set();

    for (const broadcast of this.selectedBroadcasts) {
      broadcastIds.add(broadcast.id);
    }

    // for (const channel of this.selectedChannels) {
    //   broadcastIds.add(channel.broadcast.broadcastId);
    //   channelIds.add(channel.id);
    // }

    return {
      broadcastIds: Array.from(broadcastIds),
    };
  }

  addAnswer() {
    this.pollData.answerVotes.push(this.newAnswer());
    this.form.resetValidation(); // Resets validation for votesPerUser and allows the user to re-trigger validation on submit / value changes
  }

  removeAnswer(index: number) {
    this.pollData.answerVotes.splice(index, 1);
  }

  newAnswer(value = ""): PollAnswer {
    return {
      answerId: "",
      value: value,
      voteCount: 0,
    };
  }

  get answerRules(): FormRule[] {
    return [stringLength(1, 250)];
  }

  get disabledAddAnswer() {
    return this.editOnlyBroadcasts
      ? true
      : this.pollData.answerVotes.length >= 25;
  }

  get pollTypeItems(): {
    label: string;
    value: SVPollType;
  }[] {
    const typeOptions = Object.values(SVPollType);

    let items = typeOptions.map((option) => {
      return {
        label: getPollTypeSelectionLabel(option),
        value: option,
      };
    });

    return items;
  }

  get pollStatusItems(): {
    label: string;
    value: PollStatusSelection;
  }[] {
    const statusOptions = Object.values(PollStatusSelection);

    let items = statusOptions
      .filter(
        (status) =>
          status === PollStatusSelection.PREPARING ||
          status === PollStatusSelection.PRE_VOTE ||
          status === PollStatusSelection.HIDDEN
      )
      .map((option) => {
        return {
          label: getPollStatusSelectionLabel(option),
          value: option,
        };
      });

    return items;
  }

  get groupPollItems(): {
    label: string;
    value: PollGroupSelection;
  }[] {
    const statusOptions = Object.values(PollGroupSelection);

    const items = statusOptions.map((option) => {
      return {
        label: getPollGroupSelectionLabel(option),
        value: option,
      };
    });

    return items;
  }

  get pollMemberStatusItems(): {
    label: string;
    value: PollMemberStatusSelection;
  }[] {
    const statusOptions = Object.values(PollMemberStatusSelection);

    const items = statusOptions.map((option) => {
      return {
        label: getMemberStatusSelectionLabel(option),
        value: option,
      };
    });

    return items;
  }

  addPollAnswerText(): string {
    return this.$i18n.t("polls.addAnswer").toString();
  }

  createNewPollAnswers(): NewPollAnswerDto[] {
    return this.pollData.answerVotes.map((answer: PollAnswer) => {
      return {
        value: answer.value,
      };
    });
  }

  get createPollBtnDisabled() {
    return this.saving || !this.formValid;
  }
}
